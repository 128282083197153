import React from 'react';

import { AuthProvider } from './Auth';
import { LanguageProvider } from './Language';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LanguageProvider>{children}</LanguageProvider>
  </AuthProvider>
);

export default AppProvider;
