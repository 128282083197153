import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

interface IAddress {
  id: string;
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zip_code: string;
}
interface IUserResponse {
  id: string;
  name: string;
  email: string;
  phone: string;
  display_name: string;
  display_email: string;
  display_phone: string;
  biography: string;
  favorite_quote: string;
  languages: string;
  skype: string;
  slug: string;
  avatar: {
    avatar_url: string;
  };
  file?: {
    file_url: string;
  };
  address: IAddress;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  phone: string;
  display_name: string;
  display_email: string;
  display_phone: string;
  biography: string;
  favorite_quote: string;
  languages: string;
  skype: string;
  slug: string;
  avatar_url: string;
  address?: IAddress;
  bio_photo?: string;
}

interface IAuthStateResponse {
  token: string;
  user: IUserResponse;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@Membership:token');
    const user = localStorage.getItem('@Membership:user');

    if (token && user) {
      api.defaults.headers.authorization = `Barear ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post<IAuthStateResponse>('coaches/sessions', {
      email,
      password,
    });

    const { token, user: userData } = response.data;

    const user: IUser = {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      display_name: userData.display_name,
      display_email: userData.display_email,
      display_phone: userData.display_phone,
      biography: userData.biography,
      favorite_quote: userData.favorite_quote,
      languages: userData.languages,
      skype: userData.skype,
      slug: userData.slug,
      avatar_url: userData.avatar.avatar_url,
      address: userData.address,
      bio_photo: userData.file ? userData.file.file_url : '',
    };

    localStorage.setItem('@Membership:token', token);
    localStorage.setItem('@Membership:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Barear ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Membership:token');
    localStorage.removeItem('@Membership:user');

    setData({} as IAuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@Membership:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
