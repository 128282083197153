import styled from 'styled-components';
import { lighten } from 'polished';

interface IBioPhoto {
  src: string;
}

export const Container = styled.div`
  .p-relative {
    z-index: 1;
  }

  .bg-gray {
    background-color: #202020;
    border-radius: 20px;
    z-index: -1;
  }

  ul {
    list-style: none;

    b {
      color: #969696;
      font-weight: normal;
    }

    span {
      color: #cbccce;
      font-weight: 700;
    }
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;

    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;
    background-image: none;

    span,
    input,
    textarea {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .radius-photo-bio {
    border-radius: 65px;
  }

  .mh-description {
    min-height: 150px;
  }

  .mh-500px {
    min-height: 500px;
  }

  h2 {
    color: #cbccce;
  }
  p {
    color: #6e6e6e;
  }

  button {
    padding: 13px 12px;

    opacity: 1 !important;
    color: #18191a !important;
    background: #969696;
    border-radius: 15px;
    cursor: default;

    small {
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 1199px) {
    .mh-description {
      min-height: 345px;
    }
  }

  @media screen and (max-width: 991px) {
    .mh-quote {
      min-height: 168px;
    }
    .mh-description {
      min-height: 150px;
    }

    .skeleton {
      border-radius: 45px;
    }
  }

  @media screen and (max-width: 767px) {
    .mh-quote {
      min-height: 50px;
    }

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      width: auto;
    }
  }

  @media screen and (max-width: 350px) {
    button {
      padding: 13px 7px;
    }
  }
`;

export const BioPhoto = styled.div<IBioPhoto>`
  width: 400px;
  height: 500px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 65px;
  margin-top: -30px;

  top: 100px;
  left: -70px;

  @media screen and (max-width: 1399px) {
    width: 363px;
    height: 408px;
  }

  @media screen and (max-width: 1199px) {
    top: 65px;
  }

  @media screen and (max-width: 991px) {
    width: 345px;
    height: 438px;
    border-radius: 45px;
    top: 47px;
    left: 19px;
  }

  @media screen and (max-width: 768px) {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -100px;
    position: relative;
    left: 0px;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 575px) {
    width: 260px;
    height: 290px;
  }
`;
