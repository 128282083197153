import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';
import check from '~/assets/icons/check.svg';

export const Container = styled.div`
  label {
    color: #c4c4c4 !important;
    font-weight: 400;
  }

  h3,
  button {
    color: #606060 !important;
  }

  button:hover {
    color: ${lighten(0.1, '#606060')} !important;
    filter: brightness(1.5);
  }

  .checkbox {
    border: solid 1px #c4c4c4;
    background-color: transparent;
    width: 17px;
    height: 17px;
    border-radius: 4px;
  }

  .active {
    color: #606060 !important;
    text-decoration: line-through;
  }

  .checked {
    background-image: url(${check});
    background-position: center;
    background-repeat: no-repeat;
    border: solid 1px #606060;
  }

  .webkit-box {
    overflow: auto;
    display: -webkit-box;
  }

  .bg-gray h3 {
    font-size: 18px !important;
  }

  .height {
    ::-webkit-scrollbar {
      background-color: transparent !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bg-gray ol li {
      border-color: transparent !important;
    }
    ol {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-height: 260px;
      overflow: hidden;
      li {
        .border-bottom {
          border-bottom: none;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    button {
      font-size: 14px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .height {
      height: 80px;
    }
  }

  @media screen and (min-width: 1400px) {
    .height {
      height: 150px;
    }

    .flex-xxl-column {
      flex-direction: column;
    }
  }
`;

export const Task = styled.div`
  textarea,
  input {
    color: #8c8c8c !important;
    resize: none;
  }
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    border-radius: 20px 20px 0 0;
  }
  .ck.ck-button,
  a.ck.ck-button {
    :hover {
      color: #414141 !important;
      background-color: #cbccca !important;
    }
  }

  .ck-on {
    color: #414141 !important;
  }

  .ck.ck-list {
    background-color: #414141;
  }
  .ck.ck-button .ck-tooltip,
  a.ck.ck-button .ck-tooltip {
    display: none !important;
  }
  .ck-toolbar {
    background: rgba(65, 65, 65, 1) !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-color: rgba(51, 51, 51, 1) !important;
    padding: 1rem !important;
  }

  .ck-content {
    background: #202020 !important;
    border: 1px solid rgba(51, 51, 51, 1) !important;
    box-shadow: none !important;
    min-height: 200px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .ck-focused {
    border: 1px solid #fff !important;
  }
`;

export const Modal = styled(ModalComponent)`
  padding: 0 !important;
  h4 {
    color: #cbccce;
  }

  .modal-body {
    padding: 2rem;
  }
  .modal-content {
    background: #1f1f1f !important;
    box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
      inset 2px 2px 2px rgba(107, 107, 107, 0.5),
      inset -1px -1px 2px rgba(43, 43, 43, 0.5);
    border-radius: 30px;
  }

  .btn-dark-grey:hover {
    background-color: ${lighten(0.1, '#4a4b4b')};
  }
`;

export const Skeleton = styled.div``;
