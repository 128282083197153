import React, { useCallback, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { IoIosDocument } from 'react-icons/io';
import { Container, Task, Modal, Skeleton } from './styles';
import Input from '~/components/Input';

import taskIcon from '~/assets/icons/task.svg';
import plusIcon from '~/assets/icons/plus-icon.svg';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

interface ITask {
  id: string;
  content: string;
  completed: boolean;
}

const Tasks: React.FC = () => {
  const { language } = useLanguage();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get('tasks/coaches')
      .then((response) => {
        setTasks(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSubmitTask = useCallback(
    async (data) => {
      try {
        if (data.task.length > 0) {
          const formData = {
            content: data.task,
          };

          const response = await api.post('tasks/coaches', formData);
          if (response.data) {
            const taskData: ITask = {
              id: response.data.id,
              content: data.task,
              completed: false,
            };
            setTasks((state) => [...state, taskData]);
          }
        }
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
    [handleClose]
  );

  const handleSelect = useCallback(
    async (task) => {
      try {
        const formData = {
          content: task.content,
          completed: !task.completed,
        };

        const response = await api.put(`tasks/coaches/${task.id}`, formData);

        if (response.data) {
          const newTasks = tasks.slice();
          const taskIndex = newTasks.findIndex(
            (taskData) => taskData.id === task.id
          );
          if (taskIndex >= 0) {
            newTasks[taskIndex].completed = !newTasks[taskIndex].completed;
            setTasks(newTasks);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [tasks]
  );

  return (
    <Container className="row mx-0 tasks">
      <div className="col-5 col-xxl-12 order-0 pl-0">
        <h3 className="h6 mb-3 font-weight-600">
          {language.component_tasks.h3_1}
        </h3>
      </div>
      {loading && (
        <Skeleton className="col-12 pl-0 height my-3 my-lg-0 task-checks order-2 order-xxl-1">
          <div className="row">
            <div className="col-sm-6 col-xxl-12 form-check d-flex flex-xxl-column">
              <label className="mt-2 form-check-label d-flex align-items-center">
                <input
                  type="checkbox"
                  className="d-none form-check-input mr-4"
                />
                <div className="skeleton checkbox mr-3" />
                <span className="skeleton">#### #####</span>
              </label>
            </div>
            <div className="col-sm-6 col-xxl-12 form-check d-flex flex-xxl-column">
              <label className="mt-2 form-check-label d-flex align-items-center">
                <input
                  type="checkbox"
                  className="d-none form-check-input mr-4"
                />
                <div className="skeleton checkbox mr-3" />
                <span className="skeleton">#### #####</span>
              </label>
            </div>
          </div>
        </Skeleton>
      )}
      <div
        className={`${
          loading && 'd-none'
        } col-12 pl-0 height my-3 my-lg-0 overflow-auto task-checks order-2 order-xxl-1`}
      >
        {tasks.length > 0 ? (
          <>
            <div className="row">
              {tasks.map((task) => (
                <div
                  key={task.id}
                  className="col-sm-6 col-xxl-12 form-check d-flex flex-xxl-column"
                >
                  <label
                    className={`mt-2 form-check-label d-flex align-items-center ${
                      task.completed ? 'active' : ''
                    }`}
                    htmlFor={`task_${task.id}`}
                    key={task.id}
                  >
                    <input
                      type="checkbox"
                      className="d-none form-check-input mr-4"
                      id={`task_${task.id}`}
                      onChange={() => handleSelect(task)}
                      checked={task.completed}
                    />
                    <div
                      className={`checkbox mr-3 ${
                        task.completed ? 'checked' : ''
                      }`}
                    />
                    {task.content}
                  </label>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="row h-100 align-items-center">
            <div className="col d-flex justify-content-center">
              <h3 className="h6 mb-0">
                <IoIosDocument size={20} className="mr-1 mb-1" />
                {language.component_tasks.h3_2}
              </h3>
            </div>
          </div>
        )}
      </div>

      <div className="col-7 col-xxl-12 order-1 order-xxl-2 pr-0 px-xxl-0 text-right">
        <button
          type="button"
          className="d-xxl-flex align-items-center btn-trasnparent mx-xxl-auto px-3 px-sm-4 px-xxl-5 py-2 mt-xxl-3"
          onClick={handleShow}
        >
          <img
            src={taskIcon}
            alt={language.component_tasks.img}
            className="mr-2 mr-sm-3"
          />
          {language.component_tasks.button}
        </button>
      </div>
      <Modal
        show={show}
        size="lg"
        centered
        onHide={handleClose}
        className="unlock-modal bg-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto mt-5">
            <div className="d-flex align-items-end">
              <img
                src={plusIcon}
                alt={language.component_tasks.modal_img}
                className="mr-3"
              />
              <h4 className="mb-0">{language.component_tasks.modal_h4}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="text-center">{language.component_tasks.modal_p}</p>
          <Form onSubmit={handleSubmitTask}>
            <div className="my-5 w-100 w-lg-75 mx-auto">
              <Task>
                <Input
                  placeholder={language.component_tasks.modal_placeholder_1}
                  name="task"
                  className="px-3 text-left"
                />
              </Task>
            </div>

            <button
              type="submit"
              className="mb-4 btn-dark-grey w-100 w-lg-75 py-2 py-sm-3"
            >
              {language.component_tasks.modal_button}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Tasks;
