import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import { Container, Menu } from './styles';
import en from '~/assets/icons/eua-flag.png';
import es from '~/assets/icons/spain-flag.png';
import logo from '~/assets/logotipos/logo.svg';

const Header: React.FC = () => {
  const { language, handleSelectIdiom, idiom } = useLanguage();
  const location = useLocation();
  const { /* user */ signOut } = useAuth();
  const [active, setActive] = useState(false);
  const [lang] = useState(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  });

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <Container className="vh-lg-100 d-flex flex-lg-column justify-content-between justify-content-lg-start align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img src={logo} alt="logo" className="pl-2 pl-lg-0 w-75" />
        </Link>
      </div>
      <Menu active={active} className="w-auto h-lg-100 w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="d-flex flex-column h-lg-100 p-absolute p-lg-relative menu-group active">
          <div className="d-flex flex-column pl-4 px-lg-0 py-5 py-lg-3 py-xxl-4 h-lg-100 menu">
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/dashboard`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/dashboard.*/) ? 'active' : ''
              }`}
            >
              <div className="dashboard d-none d-lg-block" />
              <small>{language.header.a_1}</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/members`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/members.*/) ? 'active' : ''
              }`}
            >
              <div className="students d-none d-lg-block" />
              <small>{language.header.a_2}</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/calendar`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/calendar.*/) ? 'active' : ''
              }`}
            >
              <div className="calendar d-none d-lg-block" />
              <small>{language.header.a_3}</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/profile`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/profile.*/) ? 'active' : ''
              }`}
            >
              <div className="profile d-none d-lg-block" />
              <small>{language.header.a_4}</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/faq`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/faq.*/) ? 'active' : ''
              }`}
            >
              <div className="faq d-none d-lg-block" />
              <small>{language.header.a_5}</small>
            </Link>
          </div>
          <div className="d-flex flex-column pl-4 px-lg-0 py-3 logoff">
            <div className="d-flex justify-content-lg-center flex-lg-column">
              <button
                type="button"
                className={`${
                  idiom === 'en' ? 'flag-text-light' : ''
                } min-height flag-text border-0 bg-transparent menu-item pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                onClick={() => handleSelectIdiom('en')}
              >
                <img
                  src={en}
                  alt="EN"
                  className={`${
                    idiom === 'en' ? 'active-idiom' : ''
                  } mr-1 mr-lg-0`}
                />
                <span className="d-none d-lg-block">
                  {language.header.button_1}
                </span>
              </button>
              <button
                type="button"
                className={`${
                  idiom === 'es' ? 'flag-text-light' : ''
                } min-height flag-text border-0 bg-transparent menu-item mt-0 mt-xxl-2 pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                onClick={() => handleSelectIdiom('es')}
              >
                <img
                  src={es}
                  alt="ES"
                  className={`${
                    idiom === 'es' ? 'active-idiom' : ''
                  } mr-1 mr-lg-0`}
                />
                <span className="d-none d-lg-block">
                  {language.header.button_2}
                </span>
              </button>
            </div>
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto" />
              <small>{language.header.button_3}</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
