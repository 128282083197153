import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import Header from '~/components/Header';

import { Wrapper, HeaderSpacer, Separator } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { signOut } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get(`/`).catch(() => {
      signOut();
    });
  }, [location, signOut]);

  return (
    <Wrapper>
      <HeaderSpacer className="margin-top">
        <Header />
      </HeaderSpacer>
      <Separator className={`${location.pathname.match('/faq') ? 'px-0' : ''}`}>
        {children}
      </Separator>
    </Wrapper>
  );
};

export default DefaultLayout;
