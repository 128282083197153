import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MdCameraAlt } from 'react-icons/md';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import {
  Container,
  Welcome,
  ContactInfo,
  PersonalInformation,
  Avatar,
  BioPhoto,
} from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputTags, { IValue } from '~/components/InputTags';
import InputAddress from '~/components/InputAddress';
import imgThumb from '~/assets/icons/img_thumb.svg';

import languagesData from '~/utils/languages.json';
import { useLanguage } from '~/hooks/Language';

const profilePhoto = 'http://cdn.wiserr.io/avatars/default-avatar.png';

interface IParams {
  slug: string;
}

interface coachFormData {
  name: string;
  email: string;
  phone: string;
  address: string;
  bio: string;
  favorite_quote: string;
  display_name: string;
  display_phone: string;
  display_email: string;
  skype: string;
  zipCode: string;
  country: string;
  street: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
  complement: string;
  slug: string;
}

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

const CoachesUpdate: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const history = useHistory();
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [coachId, setCoachId] = useState('');
  const [avatar, setAvatar] = useState('');
  const [bioPhoto, setBioPhoto] = useState('');
  const [avatarSelected, setAvatarSelectedSelected] = useState<File | null>(
    null
  );
  const [bioPhotoSelected, setBioPhotoSelectedSelected] = useState<File | null>(
    null
  );
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayPhone, setDisplayPhone] = useState('');
  const [languages, setLanguages] = useState<IValue[]>([]);
  const [skypeData, setSkype] = useState('');
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [biography, setBiography] = useState('');
  const [favoriteQuote, setFavoriteQuote] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    setCoachId(user.id);
    setAvatar(user.avatar_url);
    setFullName(user.name);
    setDisplayName(user.display_name);
    setEmailAddress(user.email);
    setDisplayEmail(user.display_email);
    setPhoneNumber(user.phone);
    setDisplayPhone(user.display_phone);
    setLanguages([
      {
        value: user.languages,
      },
    ]);
    setSkype(user.skype);
    setAddressId(user.address ? user.address.id : '');
    setZipCode(user.address ? user.address.zip_code : '');
    setCountry(user.address ? user.address.country : '');
    setStreet(user.address ? user.address.street : '');
    setNumber(user.address ? user.address.number : '');
    setNeighborhood(user.address ? user.address.neighborhood : '');
    setState(user.address ? user.address.state : '');
    setCity(user.address ? user.address.city : '');
    setComplement(user.address ? user.address.complement : '');
    setBiography(user.biography);
    setFavoriteQuote(user.favorite_quote);
    setBioPhoto(user.bio_photo || '');
    setLoading(false);
  }, [user]);

  const handleSelectAvatar = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setAvatar(URL.createObjectURL(file));
        setAvatarSelectedSelected(file);
      } else {
        setAvatar('');
        setAvatarSelectedSelected(null);
      }
    }
  }, []);

  const handleSelectBioPhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setBioPhoto(URL.createObjectURL(file));
          setBioPhotoSelectedSelected(file);
        } else {
          setBioPhoto('');
          setBioPhotoSelectedSelected(null);
        }
      }
    },
    []
  );

  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  }, []);

  const handleChangeDisplayName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayName(e.target.value);
    },
    []
  );

  const handleChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  }, []);

  const handleChangeDisplayEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayEmail(e.target.value);
    },
    []
  );

  const handleChangePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  }, []);

  const handleChangeDisplayPhone = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayPhone(e.target.value);
    },
    []
  );

  const handleChangeSkype = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSkype(e.target.value);
  }, []);

  const handleChangeZipCode = useCallback((address: IAddress) => {
    const {
      zipCode,
      country,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = address;
    setZipCode(zipCode);
    setCountry(country);
    setStreet(street);
    setNumber(number);
    setNeighborhood(neighborhood);
    setState(state);
    setCity(city);
    setComplement(complement);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.data);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.data);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.data);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.data);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.data);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.data);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.data);
  }, []);

  const handleSelectLanguage = useCallback((value) => {
    setLanguages(value);
  }, []);

  const handleChangeFavoriteQuote = useCallback((e) => {
    setFavoriteQuote(e.target.value);
  }, []);
  const handleChangeBiography = useCallback((e) => {
    setBiography(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data: coachFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.yup_profile.name),
          email: Yup.string()
            .email(language.yup_profile.email_valid)
            .required(language.yup_profile.email_required),
          phone: Yup.string().required(language.yup_profile.phone),
          bio: Yup.string().required(language.yup_profile.bio),
          favorite_quote: Yup.string().required(language.yup_profile.quote),
          display_name: Yup.string().required(
            language.yup_profile.display_name
          ),
          display_phone: Yup.string().required(
            language.yup_profile.display_phone
          ),
          display_email: Yup.string()
            .email(language.yup_profile.display_email_valid)
            .required(language.yup_profile.display_email_required),
          zipCode: Yup.string().required(language.yup_profile.zipCode),
          country: Yup.string().required(language.yup_profile.country),
          street: Yup.string().required(language.yup_profile.street),
          number: Yup.string().required(language.yup_profile.number),
          neighborhood: Yup.string().required(
            language.yup_profile.neighborhood
          ),
          state: Yup.string().required(language.yup_profile.state),
          city: Yup.string().required(language.yup_profile.city),
          complement: Yup.string().required(language.yup_profile.complement),
          skype: Yup.string().required(language.yup_profile.skype),
          languages: Yup.string().when('$languagesIsFilled', {
            is: (languagesIsFilled: boolean) => languagesIsFilled,
            then: Yup.string(),
            otherwise: Yup.string().required(language.yup_profile.languages),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            languagesIsFilled: languages.length > 0,
          },
        });

        let responseAvatar: any;
        if (avatarSelected) {
          const avatarData = new FormData();
          avatarData.append('avatar', avatarSelected as File);
          responseAvatar = await api.post('avatars', avatarData);
        } else {
          responseAvatar = {
            data: {
              avatar_url: avatar,
            },
          };
        }

        if (responseAvatar.data) {
          const {
            name,
            email,
            phone,
            address,
            bio,
            favorite_quote,
            display_name,
            display_phone,
            display_email,
            skype,
            zipCode,
            country,
            street,
            number,
            neighborhood,
            state,
            city,
            complement,
          } = data;

          const newLanguages = languages.map((language) => language.value);

          const formData = {
            name,
            email,
            phone,
            address,
            biography: bio,
            favorite_quote,
            display_name,
            display_phone,
            display_email,
            skype,
            languages: newLanguages.join(', '),
          };

          const response = await api.put(`coaches/profile`, formData);

          if (response.data) {
            const formAddressData = {
              coach_id: response.data.id,
              zip_code: zipCode,
              country,
              street,
              number,
              neighborhood,
              city,
              complement,
              state,
            };

            let addressResponse: any;
            if (addressId) {
              addressResponse = await api.put(
                `adresses/coaches/${addressId}`,
                formAddressData
              );
            } else {
              addressResponse = await api.post(
                'adresses/coaches',
                formAddressData
              );
            }

            let responseBioPhoto: any;
            if (bioPhotoSelected) {
              const bioPhotoData = new FormData();
              bioPhotoData.append('foreign_id', response.data.id);
              bioPhotoData.append('file', bioPhotoSelected as File);

              responseBioPhoto = await api.post('files/coaches', bioPhotoData);
            } else {
              responseBioPhoto = {
                data: {
                  file_url: bioPhoto,
                },
              };
            }

            if (responseBioPhoto.data) {
              Swal.fire(
                language.yup_profile.success_1,
                language.yup_profile.success_2,
                'success'
              ).then(() => {
                setLoading(false);
                const userData = {
                  id: response.data.id,
                  name: response.data.name,
                  email: response.data.email,
                  phone: response.data.phone,
                  display_name: response.data.display_name,
                  display_email: response.data.display_email,
                  display_phone: response.data.display_phone,
                  biography: response.data.biography,
                  favorite_quote: response.data.favorite_quote,
                  languages: response.data.languages,
                  skype: response.data.skype,
                  slug: response.data.id,
                  avatar_url: responseAvatar.data.avatar_url,
                  address: addressResponse.data,
                  bio_photo: responseBioPhoto.data.file_url,
                };
                updateUser(userData);
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire('Opss...', language.yup_profile.error, 'error').then(() =>
            setLoading(false)
          );
        }
      }
    },
    [
      addressId,
      avatar,
      avatarSelected,
      bioPhoto,
      bioPhotoSelected,
      language.yup_profile.bio,
      language.yup_profile.city,
      language.yup_profile.complement,
      language.yup_profile.country,
      language.yup_profile.display_email_required,
      language.yup_profile.display_email_valid,
      language.yup_profile.display_name,
      language.yup_profile.display_phone,
      language.yup_profile.email_required,
      language.yup_profile.email_valid,
      language.yup_profile.error,
      language.yup_profile.languages,
      language.yup_profile.name,
      language.yup_profile.neighborhood,
      language.yup_profile.number,
      language.yup_profile.phone,
      language.yup_profile.quote,
      language.yup_profile.skype,
      language.yup_profile.state,
      language.yup_profile.street,
      language.yup_profile.success_1,
      language.yup_profile.success_2,
      language.yup_profile.zipCode,
      languages,
      updateUser,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl px-0 px-sm-3">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 pt-3 pb-3">
            <Welcome>
              <h1 className="h4 h2-sm">{language.profile.h1}</h1>
            </Welcome>
          </div>
          <div className="col-xxl-3 col-lg-12 mb-4 mb-xxl-0">
            <ContactInfo className="h-100 py-5 d-flex flex-column">
              <div className="row">
                <div className="col-xxl-12 col-lg-3 col-md-3">
                  <div className="row h-md-100">
                    <div className="col-12 d-md-flex pr-md-0 pr-lg-3 pr-xl-4 pr-xxl-3 align-items-md-center text-center order-2 order-xxl-1">
                      <div className="border-contact w-100">
                        <Avatar
                          src={`${!loading && (avatar || profilePhoto)}`}
                          htmlFor="avatar"
                          className={`${loading && 'skeleton rounded-circle'}`}
                        >
                          <div className={`${loading && 'd-none'}`}>
                            <MdCameraAlt size={20} color="#fff" />
                          </div>
                          <Input
                            type="file"
                            name="avatar"
                            id="avatar"
                            className="d-none"
                            onChange={handleSelectAvatar}
                          />
                        </Avatar>
                      </div>
                    </div>
                    <div className="col-12 order-1 order-xxl-2">
                      <hr className="border-gray my-5 d-xxl-block d-none" />
                      <h3 className="h5 font-weight-400 text-center text-xxl-left mt-xxl-3 mb-3 mb-xxl-4">
                        {language.profile.h3_1}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-12 col-lg-9 col-md-9 ">
                  <div className="row">
                    <div className="mt-3 col-sm-6 col-xxl-12">
                      <label htmlFor="display_name" className="small">
                        {language.profile.label_1}
                      </label>
                      <Input
                        id="display_name"
                        name="display_name"
                        value={displayName || fullName}
                        onChange={handleChangeDisplayName}
                        className={`${loading && 'skeleton'} border-gray`}
                      />
                    </div>
                    <div className="mt-3 mt-xxl-4 col-sm-6 col-xxl-12">
                      <label htmlFor="display_phone" className="small">
                        {language.profile.label_2}
                      </label>
                      <Input
                        id="display_phone"
                        name="display_phone"
                        onChange={handleChangeDisplayPhone}
                        value={displayPhone || phoneNumber}
                        className={`${loading && 'skeleton'} border-gray`}
                      />
                    </div>
                    <div className="mt-4 col-sm-6 col-xxl-12">
                      <label htmlFor="display_email" className="small">
                        {language.profile.label_3}
                      </label>
                      <Input
                        type="email"
                        id="display_email"
                        name="display_email"
                        value={displayEmail || emailAddress}
                        onChange={handleChangeDisplayEmail}
                        className={`${loading && 'skeleton'} border-gray`}
                      />
                    </div>
                    <div className="mt-4 col-sm-6 col-xxl-12">
                      <label htmlFor="skype" className="small">
                        {language.profile.label_4}
                      </label>
                      <Input
                        id="skype"
                        name="skype"
                        className={`${loading && 'skeleton'} border-gray`}
                        value={skypeData}
                        onChange={handleChangeSkype}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ContactInfo>
          </div>
          <div className="col-xxl-9 col-lg-12">
            <PersonalInformation className="h-100">
              <h3 className="h5 mb-4">{language.profile.h3_2}</h3>
              <div className="row">
                <div className="col-lg-6 my-2">
                  <label htmlFor="name">{language.profile.label_5}</label>
                  <Input
                    name="name"
                    id="name"
                    className={`${loading && 'skeleton'} border-gray`}
                    value={fullName}
                    onChange={handleChangeName}
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="email">{language.profile.label_6}</label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    className={`${loading && 'skeleton'} border-gray`}
                    value={emailAddress}
                    onChange={handleChangeEmail}
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="phone">{language.profile.label_7}</label>
                  <Input
                    name="phone"
                    id="phone"
                    className={`${loading && 'skeleton'} border-gray`}
                    value={phoneNumber}
                    onChange={handleChangePhone}
                  />
                </div>
                <div className="col-sm-6 col-lg-4 my-2">
                  <label htmlFor="email">{language.profile.label_8}</label>
                  <InputAddress
                    name="zipCode"
                    value={zipCodeData}
                    onChange={handleChangeZipCode}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-sm-6 col-lg-4 my-2">
                  <label htmlFor="email">{language.profile.label_9}</label>
                  <Input
                    name="country"
                    value={countryData}
                    onChange={handleChangeCountry}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-lg-4 my-2">
                  <label htmlFor="email">{language.profile.label_10}</label>
                  <Input
                    name="street"
                    value={streetData}
                    onChange={handleChangeStreet}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="email">{language.profile.label_11}</label>
                  <Input
                    name="number"
                    value={numberData}
                    onChange={handleChangeNumber}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="email">{language.profile.label_12}</label>
                  <Input
                    name="neighborhood"
                    value={neighborhoodData}
                    onChange={handleChangeNeighborhood}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-sm-6 col-lg-6 my-2">
                  <label htmlFor="email">{language.profile.label_13}</label>
                  <Input
                    name="state"
                    value={stateData}
                    onChange={handleChangeState}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-sm-6 col-lg-5 my-2">
                  <label htmlFor="email">{language.profile.label_14}</label>
                  <Input
                    name="city"
                    value={cityData}
                    onChange={handleChangeCity}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-lg-7 my-2">
                  <label htmlFor="email">{language.profile.label_15}</label>
                  <Input
                    name="complement"
                    value={complementData}
                    onChange={handleChangeComplement}
                    className={`${loading && 'skeleton'} border-gray`}
                  />
                </div>
                <div className="col-lg-7">
                  <div className="mt-3">
                    <label htmlFor="languages" className="small">
                      {language.profile.label_16}
                    </label>
                    <InputTags
                      name="languages"
                      id="languages"
                      data={languages}
                      foundData={languagesData}
                      onChange={handleSelectLanguage}
                      className={`${
                        loading && 'skeleton'
                      } border-gray language-input pt-2`}
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="mt-3">
                    <label htmlFor="favorite_quote" className="small">
                      {language.profile.label_17}
                    </label>
                    <Textarea
                      id="favorite_quote"
                      name="favorite_quote"
                      className={`${
                        loading && 'skeleton h-96px'
                      } border-gray h-96px`}
                      value={favoriteQuote}
                      onChange={handleChangeFavoriteQuote}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-md-4">
                <div className="col-md-5 col-lg-4">
                  <div className="w-100 mt-4 pt-2">
                    <label
                      htmlFor="bio_photo"
                      className={`${
                        loading && 'skeleton'
                      } btn bg-gray height text-center w-100 my-auto p-0`}
                    >
                      {bioPhoto ? (
                        <BioPhoto
                          src={bioPhoto}
                          alt={language.profile.bio_photo}
                          className={`${loading && 'd-none'} h-100 w-100`}
                        />
                      ) : (
                        <div
                          className={`${
                            loading && 'd-none'
                          } py-2 h-100 row align-items-center`}
                        >
                          <div className="col-12">
                            <BioPhoto
                              src={imgThumb}
                              alt={language.profile.bio_photo}
                              className="my-4"
                            />
                          </div>
                          <div className="col-12">
                            <p className="h5">
                              <small>{language.profile.p_1}</small>
                            </p>
                            <p className="h5 text-white text-decoration-underline">
                              <small>{language.profile.p_2}</small>
                            </p>
                          </div>
                        </div>
                      )}
                    </label>
                    <Input
                      type="file"
                      id="bio_photo"
                      name="bio_photo"
                      className="d-none"
                      onChange={handleSelectBioPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-7 col-lg-8 d-flex flex-column justify-content-between mt-3 mt-md-0">
                  <div>
                    <label htmlFor="bio" className="small">
                      {language.profile.label_18}
                    </label>
                    <Textarea
                      id="bio"
                      name="bio"
                      className={`${
                        loading && 'skeleton'
                      } border-gray h-168px p-3`}
                      value={biography}
                      onChange={handleChangeBiography}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex flex-column justify-content-between">
                  <div className="row">
                    <div className="col-md-6 col-lg-4 my-3 col-update">
                      <Link
                        to={`${process.env.PUBLIC_URL}/profile/bio/${user.slug}`}
                        className={`${
                          loading && 'skeleton'
                        } d-block my-bio w-100 py-3`}
                      >
                        <span className="d-block py-1">
                          {language.profile.a}
                        </span>
                      </Link>
                    </div>
                    <div className="col-md-6 col-lg-4 my-md-3 col-update">
                      <button
                        type="submit"
                        className={`${
                          loading && 'skeleton'
                        } w-100 submit-button py-3`}
                      >
                        <span className="d-block py-1">
                          {language.profile.button}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PersonalInformation>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default CoachesUpdate;
