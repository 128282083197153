/* eslint-disable prefer-destructuring */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useHistory, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';

import {
  Avatar,
  Container,
  PersonalInformation,
  Profile,
  Welcome,
  Skeleton,
} from './styles';
import Courses from '~/components/Courses';
import Input from '~/components/Input';
import InputTags, { IValue } from '~/components/InputTags';

import profilePhoto from '~/assets/defaults/profile-photo.png';
import course1 from '~/assets/defaults/course-1.png';
import course2 from '~/assets/defaults/course-2.png';
import arrow from '~/assets/icons/arrow.svg';
import locked from '~/assets/icons/locked.svg';
import unlock from '~/assets/icons/unlock.svg';
import { useLanguage } from '~/hooks/Language';

interface IStudent {
  id: string;
  avatar_url: string;
  username: string;
  phone: string;
  referral_code: string;
  date_joined: string;
  member_type: string;
  referrer: string;
  products: IValue[];
}

interface ILesson {
  id: string;
  title: string;
  description: string;
  coach_unlock: string;
}

interface ICourse {
  id: string;
  title: string;
  lessons: ILesson[];
  selected: boolean;
  percent: string;
}

interface IParams {
  userID: string;
}

const Update: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [student, setStudent] = useState<IStudent>({} as IStudent);
  const [unlockClasses, setUnlockClasses] = useState(false);
  const [show, setShow] = useState(false);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [courseSelected, setCourseSelected] = useState<ICourse>({} as ICourse);
  const [lesson, setLesson] = useState<ILesson>({} as ILesson);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`coaches/students/${params.userID}`)
      .then(async (response) => {
        let type = language.members_update.student;
        if (
          response.data.affiliate &&
          response.data.affiliate.active_affiliate
        ) {
          if (response.data.affiliate.franchise_owner) {
            type = language.members_update.franchise_owner;
          } else {
            type = language.members_update.affiliate;
          }
        }

        const products: IValue[] = [];

        response.data.affiliate.affiliatesProducts.forEach(
          (affiliatesProduct: any) => {
            const productData: IValue = {
              id: affiliatesProduct.product.id,
              value: affiliatesProduct.product.name,
            };

            products.push(productData);
          }
        );

        const data: IStudent = {
          id: response.data.id,
          avatar_url: response.data.avatar
            ? response.data.avatar.avatar_url
            : '',
          username: response.data.username || response.data.name,
          phone: response.data.phone,
          referral_code: response.data.referral_code,
          date_joined: format(
            parseISO(response.data.created_at),
            'MMMM dd, yyyy, hh:mm b'
          ),
          member_type: type,
          referrer: response.data.referrer
            ? response.data.referrer.affiliate.student.name
            : '',
          products,
        };

        const coursesData: ICourse[] = [];

        let lessonSelected: ILesson = {} as ILesson;
        let courseDataSelected: ICourse = {} as ICourse;

        response.data.courseStudent.forEach(
          (courseStudent: any, index: number) => {
            const lessonsStudent: any = [];
            courseStudent.course.lessons.forEach((lessonData: any) => {
              lessonData.lessonStudent.forEach((lessonStudent: any) => {
                if (lessonStudent.student_id === response.data.id) {
                  lessonsStudent.push(lessonStudent);
                }
              });
            });

            const lessonsComplete = lessonsStudent.filter(
              (lessonStudent: any) => lessonStudent.completed
            );

            const percent =
              (lessonsComplete.length * 100) / lessonsStudent.length;

            const course: ICourse = {
              id: courseStudent.course.id,
              title: courseStudent.course.title,
              lessons: courseStudent.course.lessons,
              selected: index === 0,
              percent: percent.toFixed(2),
            };

            if (index === 0) {
              lessonSelected = courseStudent.course.lessons[0];
              courseDataSelected = course;
            }
            coursesData.push(course);
          }
        );

        setCourses(coursesData);
        setCourseSelected(courseDataSelected);
        setLesson(lessonSelected);
        setStudent(data);
      })
      .finally(() => setLoading(false));
  }, [
    language.members_update.affiliate,
    language.members_update.franchise_owner,
    language.members_update.student,
    params,
  ]);

  const handleUnlock = useCallback(() => {
    setUnlockClasses(true);
  }, []);

  const handleClick = useCallback(
    (pageSelected: number) => {
      setLesson(courseSelected.lessons[pageSelected - 1]);
      setPage(pageSelected);
    },
    [courseSelected.lessons]
  );

  const handleSelectCourse = useCallback((course: ICourse) => {
    setCourseSelected(course);
    setLesson(course.lessons[0]);
    setPage(1);
    setCourses((state) => {
      const newCourses = state.slice();
      const courseIndex = newCourses.findIndex(
        (courseData) => courseData.selected
      );
      if (courseIndex >= 0) {
        newCourses[courseIndex].selected = false;
        const courseFoundIndex = newCourses.findIndex(
          (courseData) => courseData === course
        );

        if (courseFoundIndex >= 0) {
          newCourses[courseFoundIndex].selected = true;
        }
      }
      return newCourses;
    });
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm my-4">
                      {language.members_update.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            {loading && (
              <Skeleton className="row">
                <div className="col-lg-12 col-xxl-3 mb-5 mb-xxl-0">
                  <div className="container-xxl h-xxl-100 px-2 px-lg-3 profile py-2">
                    <div className="d-md-flex d-xxl-block align-items-center bg-user px-2 py-3 py-xl-4 py-xxl-5">
                      <div className="col-xxl-12 col-lg-3 col-md-4 px-0 px-sm-3">
                        <div className="px-0 row">
                          <div className="col-12  order-1 order-xxl-0">
                            <Profile className="text-center bd-right w-auto">
                              <div className="w-100 p-relative mb-4 mb-xxl-0">
                                <Avatar
                                  src={student.avatar_url}
                                  className="skeleton rounded-circle"
                                />
                              </div>
                            </Profile>
                          </div>
                          <div className="col-12 d-none d-xxl-block order-xxl-1">
                            <hr className="my-5 d-xxl-block d-lg-none" />
                          </div>
                          <div className="col-12 order-0 order-xxl-2">
                            <h2 className="h5 text-center text-lg-left mb-5">
                              {language.members_update.h2_1}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-lg-9 col-md-8 px-0 px-sm-3 border-mobile">
                        <div className="row">
                          <div className="col-xxl-12 col-lg-4 col-md-6">
                            <div className="my-3">
                              <label htmlFor="username">
                                {language.members_update.label_1}
                              </label>
                              <div className="p-3 w-100 skeleton input-h" />
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-4 col-md-6">
                            <div className="my-3">
                              <label htmlFor="phone">
                                {language.members_update.label_2}
                              </label>
                              <div className="p-3 w-100 skeleton input-h" />
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-4 col-md-6">
                            <div className="my-3">
                              <label htmlFor="referral_code">
                                {language.members_update.label_3}
                              </label>
                              <div className="p-3 w-100 skeleton input-h" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xxl-9">
                  <PersonalInformation className="px-sm-3 py-5 h-xxl-100">
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <h2 className="h5">{language.members_update.h2_2}</h2>
                        </div>
                        <div className="col-lg-4 mt-4 my-lg-2">
                          <label htmlFor="date_joined">
                            {language.members_update.label_4}
                          </label>
                          <div className="p-2 px-3 skeleton input-h" />
                        </div>
                        <div className="col-md-6 col-lg-4 my-4 my-lg-2">
                          <label htmlFor="member_type">
                            {language.members_update.label_5}
                          </label>
                          <div className="p-2 px-3 skeleton input-h" />
                        </div>
                        <div className="col-md-6 col-lg-4 my-4 my-lg-2">
                          <label htmlFor="referrer">
                            {language.members_update.label_6}
                          </label>
                          <div className="p-2 px-3 skeleton input-h" />
                        </div>
                        <div className="col-lg-12 mb-3 mb-lg-0">
                          <div className="mt-3">
                            <label htmlFor="products">
                              {language.members_update.label_7}
                            </label>
                            <div className="pt-5 pb-4 px-3 disabled skeleton input-h w-100" />
                          </div>
                        </div>
                        <div className="col-lg-12 mt-4">
                          <div className="courses w-100 row mx-0">
                            <div className="col-md-4">
                              <div className="py-4 py-md-5 pl-md-3">
                                <h2 className="h5 text-center text-md-left">
                                  {language.component_courses.h2}
                                </h2>
                                <div className="categories row mt-3 mt-md-4 mt-xl-5 text-left pl-3 mr-0">
                                  <div className="col-12 pl-1 mt-2">
                                    <button
                                      type="button"
                                      className="skeleton w-100 px-3 px-lg-4 py-3 selected"
                                    >
                                      ######## #### ####
                                    </button>
                                  </div>
                                  <div className="col-12 pl-1 mt-2">
                                    <button
                                      type="button"
                                      className="skeleton w-100 px-3 px-lg-4 py-3 selected"
                                    >
                                      ######## #### ####
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 d-flex flex-column justify-content-between px-sm-4 py-5">
                              <div className="w-100 mx-0 row justify-content-between">
                                <div className="col-lg-8 px-0 pr-md-3">
                                  <h5 className="h4 skeleton text-center text-md-left font-weight-bold">
                                    ######## #### ########
                                  </h5>
                                </div>
                                <div className="col-lg-4 pl-0 pl-lg-3">
                                  <p className="mb-0 mt-3 mt-lg-0 skeleton text-center text-md-left text-lg-right color-gray">
                                    /00 {language.members_update.p_videos}
                                  </p>
                                </div>
                              </div>
                              <p className="skeleton my-4 py-5 color-gray font-xs-14 font-weight-300">
                                ######## #### ######## ########
                              </p>
                              <ProgressBar
                                now={parseFloat(courseSelected.percent)}
                                className="skeleton my-4"
                              />
                              <p className="h5 text-right percent">
                                <span className="skeleton ml-auto w-50">
                                  00% {language.members_update.p_complete}
                                </span>
                              </p>

                              <div className="d-flex btn-className justify-content-between mt-5">
                                <button type="button" className="btn skeleton">
                                  {language.members_update.button_1}
                                </button>
                                <button type="button" className="btn skeleton">
                                  {language.members_update.button_2}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PersonalInformation>
                </div>
              </Skeleton>
            )}
            {Object.keys(student).length > 0 && (
              <Form
                ref={formRef}
                onSubmit={() => console.log('nada')}
                initialData={student}
              >
                <div className="row">
                  <div className="col-lg-12 col-xxl-3 mb-5 mb-xxl-0">
                    <div className="container-xxl h-xxl-100 px-2 px-lg-3 profile py-2">
                      <div className="d-md-flex d-xxl-block align-items-center bg-user px-2 py-3 py-xl-4 py-xxl-5">
                        <div className="col-xxl-12 col-lg-3 col-md-4 px-0 px-sm-3">
                          <div className="px-0 row">
                            <div className="col-12  order-1 order-xxl-0">
                              <Profile className="text-center bd-right w-auto">
                                <div className="w-100 p-relative mb-4 mb-xxl-0">
                                  <Avatar src={student.avatar_url} />
                                </div>
                              </Profile>
                            </div>
                            <div className="col-12 d-none d-xxl-block order-xxl-1">
                              <hr className="my-5 d-xxl-block d-lg-none" />
                            </div>
                            <div className="col-12 order-0 order-xxl-2">
                              <h2 className="h5 text-center text-lg-left mb-5">
                                {language.members_update.h2_1}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-12 col-lg-9 col-md-8 px-0 px-sm-3 border-mobile">
                          <div className="row">
                            <div className="col-xxl-12 col-lg-4 col-md-6">
                              <div className="my-3">
                                <label htmlFor="username">
                                  {language.members_update.label_1}
                                </label>
                                <Input
                                  disabled
                                  name="username"
                                  className="p-3"
                                  id="username"
                                />
                              </div>
                            </div>
                            <div className="col-xxl-12 col-lg-4 col-md-6">
                              <div className="my-3">
                                <label htmlFor="phone">
                                  {language.members_update.label_2}
                                </label>
                                <Input
                                  disabled
                                  name="phone"
                                  className="p-3"
                                  id="phone"
                                />
                              </div>
                            </div>
                            <div className="col-xxl-12 col-lg-4 col-md-6">
                              <div className="my-3">
                                <label htmlFor="referral_code">
                                  {language.members_update.label_3}
                                </label>
                                <Input
                                  disabled
                                  name="referral_code"
                                  className="p-3"
                                  id="referral_code"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xxl-9">
                    <PersonalInformation className="px-sm-3 py-5 h-xxl-100">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-12">
                            <h2 className="h5">
                              {language.members_update.h2_2}
                            </h2>
                          </div>
                          <div className="col-lg-4 mt-4 my-lg-2">
                            <label htmlFor="date_joined">
                              {language.members_update.label_4}
                            </label>
                            <Input
                              disabled
                              className="p-2 px-3"
                              name="date_joined"
                              id="date_joined"
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 my-4 my-lg-2">
                            <label htmlFor="member_type">
                              {language.members_update.label_5}
                            </label>
                            <Input
                              disabled
                              className="p-2 px-3"
                              name="member_type"
                              id="member_type"
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 my-4 my-lg-2">
                            <label htmlFor="referrer">
                              {language.members_update.label_6}
                            </label>
                            <Input
                              disabled
                              className="p-2 px-3"
                              name="referrer"
                              id="referrer"
                            />
                          </div>
                          <div className="col-lg-12 mb-3 mb-lg-0">
                            <div className="mt-3">
                              <label htmlFor="products">
                                {language.members_update.label_7}
                              </label>
                              <InputTags
                                id="products"
                                className="p-3 disabled"
                                name="products"
                                data={student.products}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <div className="courses w-100 row mx-0">
                              <div className="col-md-4">
                                <Courses
                                  courses={courses}
                                  onSelectCourse={handleSelectCourse}
                                />
                              </div>
                              <div className="col-md-8 d-flex flex-column justify-content-between px-sm-4 py-5">
                                <div className="w-100 mx-0 row justify-content-between">
                                  <div className="col-lg-8 px-0 pr-md-3">
                                    <h5 className="h4 text-center text-md-left font-weight-bold">
                                      {lesson ? lesson.title : ''}
                                    </h5>
                                  </div>
                                  <div className="col-lg-4 pl-0 pl-lg-3">
                                    <p className="mb-0 mt-3 mt-lg-0 text-center text-md-left text-lg-right color-gray">
                                      <span className="h4 font-weight-bold">
                                        {lesson ? page : 0}
                                      </span>{' '}
                                      /
                                      {lesson
                                        ? courseSelected.lessons.length
                                        : 0}{' '}
                                      {language.members_update.p_videos}
                                    </p>
                                  </div>
                                </div>
                                <p className="my-4 color-gray font-xs-14 font-weight-300">
                                  {lesson ? lesson.description : ''}
                                </p>
                                <ProgressBar
                                  now={parseFloat(courseSelected.percent)}
                                  className="my-4"
                                />
                                <p className="h5 text-right percent">
                                  {courseSelected.percent}%{' '}
                                  {language.members_update.p_complete}
                                </p>
                                {/* {unlockClasses ? (
                                  <button
                                    type="button"
                                    className="d-flex py-2 px-3 unlock"
                                    onClick={handleShow}
                                  >
                                    <img
                                      src={unlock}
                                      alt="Unlock Classes"
                                      className="mr-3"
                                    />
                                    Unlock Classes
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="d-flex py-2 px-3 locked"
                                    onClick={handleUnlock}
                                  >
                                    <img
                                      src={locked}
                                      alt="Unlock Classes"
                                      className="mr-3"
                                    />
                                    Unlock Classes
                                  </button>
                                )} */}
                                <div className="d-flex btn-className justify-content-between mt-5">
                                  <button
                                    type="button"
                                    onClick={() => handleClick(page - 1)}
                                    disabled={page <= 1}
                                    className="btn"
                                  >
                                    {language.members_update.button_1}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleClick(page + 1)}
                                    disabled={
                                      page >= courseSelected.lessons.length
                                    }
                                    className="btn"
                                  >
                                    {language.members_update.button_2}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PersonalInformation>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
        <Modal
          show={show}
          size="lg"
          centered
          onHide={handleClose}
          className="unlock-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto mt-5">
              <div className="d-flex">
                <img src={unlock} alt="unlock" className="mr-3" />
                <h4 className="mb-0">{language.members_update.modal_h4}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="text-center">
              {language.members_update.modal_p_1_1}{' '}
              <span className="font-weight-bold">01</span>{' '}
              {language.members_update.modal_p_1_2}{' '}
              <span className="font-weight-bold">02</span>
            </p>
            <div className="row mx-auto my-5 w-100 w-lg-75">
              <div className="col-md-5 text-center">
                <img src={course1} alt={language.members_update.modal_img_1} />
                <p className="mt-4 text-white">
                  {language.members_update.modal_p_2}
                </p>
              </div>
              <div className="col-md-2">
                <div className="h-100 text-center justify-content-center row align-items-center">
                  <img src={arrow} alt={language.members_update.modal_img_2} />
                </div>
              </div>
              <div className="col-md-5 text-center">
                <img src={course2} alt={language.members_update.modal_img_3} />
                <p className="mt-4 text-white">
                  {language.members_update.modal_p_3}
                </p>
              </div>
            </div>
            <button
              type="button"
              className="my-5 btn-dark-grey w-100 w-lg-75 py-3"
            >
              {language.members_update.modal_button}
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default Update;
