import React, { useState, useMemo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { format, parseISO, differenceInDays } from 'date-fns';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container } from './styles';

import arrowDown from '~/assets/icons/arrow-down.svg';
import { useLanguage } from '~/hooks/Language';

interface IOrders {
  amount_paid: string;
  created_at: string;
  deleted_at: string;
}

const Graph: React.FC = () => {
  const { language } = useLanguage();
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRevenuePrice, setTotalRevenuePrice] = useState('');
  const [months, setMonths] = useState<string[]>([]);
  const [monthMembers, setMonthMembers] = useState<number[]>([]);
  const [memberRefund, setMemberRefund] = useState(0);
  const [monthSales, setMonthSales] = useState<number[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    api
      .get('students')
      .then((response) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const monthsNames: string[] = [];
        const membersArr: number[] = [];
        for (let i = 5; i >= 0; i -= 1) {
          const monthCalc = currentMonth - i;
          let year = currentYear;
          if (monthCalc < 0) {
            year -= 1;
          }
          const date = new Date(year, monthCalc);
          monthsNames.push(format(date, 'MMMM'));
          const month = date.getMonth();

          const dataByMonth = response.data.filter((student: any) => {
            const studentDate = new Date(student.created_at);
            const studentMonth = studentDate.getMonth();
            return month === studentMonth;
          });
          membersArr.push(dataByMonth.length);
        }

        api.get('orders/admin').then((responseOrders) => {
          const total = responseOrders.data.reduce(
            (acumulador: number, currentValue: IOrders) => {
              return acumulador + parseFloat(currentValue.amount_paid);
            },
            0
          );

          const salesArr: number[] = [];
          for (let i = 5; i >= 0; i -= 1) {
            const monthCalc = currentMonth - i;
            let year = currentYear;
            if (monthCalc < 0) {
              year -= 1;
            }
            const date = new Date(year, monthCalc);
            monthsNames.push(format(date, 'MMMM'));
            const month = date.getMonth();

            const dataByMonth = responseOrders.data.filter((orders: any) => {
              const orderDate = new Date(orders.created_at);
              const orderMonth = orderDate.getMonth();

              return month === orderMonth;
            });
            salesArr.push(dataByMonth.length);
          }

          setMonthSales(salesArr);

          setTotalRevenue(total);
        });

        api.get('orders/canceled').then((responseOrders) => {
          const ordersData = responseOrders.data.filter((order: IOrders) => {
            const dateDiff = differenceInDays(
              parseISO(order.deleted_at),
              parseISO(order.created_at)
            );
            return dateDiff < 30;
          });

          const total = ordersData.reduce(
            (acumulador: number, currentValue: IOrders) => {
              return acumulador + parseFloat(currentValue.amount_paid);
            },
            0
          );

          setMemberRefund(total);
        });

        setMonths(monthsNames);
        setMonthMembers(membersArr);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const total = totalRevenue - memberRefund;

    setTotalRevenuePrice(formatPrice(total));
  }, [memberRefund, totalRevenue]);

  const options = useMemo(
    () => ({
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: months,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },
      colors: ['#18191A', '#37c694'],
      markers: {
        strokeColors: '#4E4F51',
      },
      fill: {
        colors: ['rgba(24,25,26,1)', 'rgba(55, 198, 148, 0.8)'],
        type: 'gradient',
        gradient: {
          gradientToColors: [
            ['rgba(24,25,26,1)', 'rgba(24,25,26,0.5)'],
            ['rgba(55, 198, 148, 1)', 'rgba(55, 198, 148, 0.5)'],
          ],
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      grid: {
        show: false,
      },
    }),
    [months]
  );

  const series = useMemo(
    () => [
      {
        name: language.component_graph.members,
        data: monthMembers,
      },
      {
        name: language.component_graph.sales,
        data: monthSales,
      },
    ],
    [
      language.component_graph.members,
      language.component_graph.sales,
      monthMembers,
      monthSales,
    ]
  );

  return (
    <Container>
      <div className="d-sm-flex align-items-center justify-content-between title-chart">
        <div>
          <h3 className="h5 font-weight-300">{language.component_graph.h3}</h3>
          <p className={`${loading && 'skeleton'}`}>
            <span className="h4 h2-sm">{totalRevenuePrice}</span>
          </p>
        </div>
        <div>
          <button
            type="button"
            className={`${
              loading && 'skeleton'
            } btn-month d-flex align-items-center justify-content-between mt-2 py-2 px-3 rounded-pill ml-auto`}
          >
            <span className="small mr-2">
              {language.component_graph.button}
            </span>
            <img src={arrowDown} alt={language.component_graph.img} />
          </button>
        </div>
      </div>
      {/* <Line data={data} /> */}
      <div className={`${loading && 'skeleton w-100'}`}>
        <ReactApexChart type="area" options={options} series={series} />
      </div>
    </Container>
  );
};

export default Graph;
