import React, { useEffect, useState } from 'react';
import { RiWhatsappFill } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import { AiFillSkype } from 'react-icons/ai';
import { BsFillBookmarkDashFill } from 'react-icons/bs';
import { FaCalendarCheck } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { setTimeout } from 'timers';
import { useAuth } from '~/hooks/Auth';

import { Container, BioPhoto } from './styles';
import Maps from '~/components/Maps';
import { useLanguage } from '~/hooks/Language';

const profilePhoto = 'http://cdn.wiserr.io/avatars/default-avatar.png';

interface IParams {
  slug: string;
}

interface ICoach {
  id: string;
  display_name: string;
  biography: string;
  display_phone: string;
  display_email: string;
  skype: string;
  bio_photo: string;
  favorite_quote: string;
}

const Bio: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [coach, setCoach] = useState<ICoach>({} as ICoach);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data: ICoach = {
      id: user.id,
      display_name: user.display_name,
      display_phone: user.display_phone,
      display_email: user.display_email,
      skype: user.skype,
      bio_photo: user.bio_photo || '',
      biography: user.biography,
      favorite_quote: user.favorite_quote,
    };
    if (user.address) {
      const addressData = `${user.address.street}, ${user.address.number}, ${user.address.neighborhood}, ${user.address.city}, ${user.address.state} - ${user.address.zip_code} - ${user.address.country}`;
      setAddress(addressData);
    }
    setCoach(data);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [user]);

  return (
    <Container className="py-5">
      <div className="container-fluid container-lg">
        <div className="row justify-content-center align-items-center p-relative">
          <div className="col-lg-10 d-flex flex-column bg-gray p-relative px-sm-5 px-md-3 py-4">
            <BioPhoto
              src={coach.bio_photo || profilePhoto}
              className={`${
                loading && 'skeleton radius-photo-bio'
              } p-md-absolute`}
            />
            <div className="row justify-content-end order-1 order-lg-0">
              <div className="col-lg-7 col-xl-8 pt-xxl-4 mt-4 mt-lg-5 px-md-5 px-lg-3">
                <h1
                  className={`${
                    loading && 'skeleton p-relative px-2'
                  } font-weight-400`}
                >
                  {coach.display_name}
                </h1>
                <p
                  className={`${
                    loading && 'skeleton p-relative w-100'
                  } h5 py-4 mh-description`}
                >
                  {coach.biography}
                </p>
              </div>
            </div>
            <div className="row justify-content-md-end justify-content-lg-start justify-content-xl-end order-0 order-lg-1">
              <div className="col-md-6 col-lg-12 col-xl-8 mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-lg-6 order-1 order-xl-0 mt-5 mt-lg-0">
                    <div className="row">
                      <div className="col-2 text-right text-md-center pl-2 pl-md-3 mt-n2 pr-0">
                        <BsFillBookmarkDashFill size={50} color="#606060" />
                      </div>
                      <div className="col-10">
                        <h2 className="h5 font-weight-400">
                          {language.profile_bio.h2}
                        </h2>
                        <p
                          className={`${
                            loading && 'skeleton p-relative h-lg-100'
                          } w-100 font-weight-300 mh-quote`}
                        >
                          {coach.favorite_quote}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-0 order-xl-1">
                    <ul>
                      <li className="mb-1 d-flex">
                        <RiWhatsappFill size={20} color="#969696" />{' '}
                        <b className="mx-1">{language.profile_bio.li}</b>{' '}
                        <span className={`${loading && 'skeleton p-relative'}`}>
                          {coach.display_phone}
                        </span>
                      </li>
                      <li className="my-3 d-flex">
                        <MdEmail size={20} color="#969696" />{' '}
                        <b className="mx-1">Email:</b>{' '}
                        <span className={`${loading && 'skeleton p-relative'}`}>
                          {coach.display_email}
                        </span>
                      </li>
                      <li className="mt-1 d-flex">
                        <AiFillSkype size={20} color="#969696" />{' '}
                        <b className="mx-1">Skype:</b>{' '}
                        <span className={`${loading && 'skeleton p-relative'}`}>
                          {coach.skype}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-xl-end order-2 order-lg-2">
              <div className="col-lg-12 col-xl-8">
                <div className="row justify-content-md-end justify-content-lg-start">
                  <div className="col-lg-6 order-1 order-md-0 d-flex d-md-block">
                    <button
                      type="button"
                      className={`${
                        loading && 'skeleton p-relative'
                      } btn mt-4 mt-md-0 mt-lg-5`}
                      disabled
                    >
                      <FaCalendarCheck
                        size={19}
                        color="#18191A"
                        className={`${loading && 'd-none'}`}
                      />
                      <small>{language.profile_bio.button}</small>
                    </button>
                  </div>
                  <div className="col-md-8 col-lg-6 order-0 order-md-1">
                    <div
                      className={`${loading && 'skeleton p-relative d-flex'}`}
                    >
                      <Maps address={address} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Bio;
