import React, { useCallback, useMemo, useState } from 'react';
import DataTable, {
  IDataTableColumn,
  IDataTableStyles,
} from 'react-data-table-component';
import { CSVLink } from 'react-csv';

import { TableContent, Pagination, Search } from './styles';
import InputDate from '~/components/InputDate';

import search from '~/assets/icons/search-icon.svg';
import table from '~/assets/icons/table-icon.svg';
import { useLanguage } from '~/hooks/Language';

interface IData {
  [key: string]: any;
}
interface ITableProps {
  title?: string;
  data: IData[];
  columns: IDataTableColumn[];
  style?: IDataTableStyles;
  searchable?: boolean;
  onSearch?(value: string): void;
  date?: boolean;
  initialDate?: Date;
  finalDate?: Date;
  onChangeStartDate?(date: Date): void;
  onChangeLastDate?(date: Date): void;
  exportable?: boolean;
  pagination?: boolean;
  onRowClicked?(e: IData): void;
}

const Table: React.FC<ITableProps> = ({
  title,
  data,
  columns,
  style,
  searchable,
  onSearch,
  date,
  initialDate,
  finalDate,
  onChangeStartDate,
  onChangeLastDate,
  exportable,
  pagination,
  onRowClicked,
}) => {
  const { language } = useLanguage();
  const [startDate, setStartDate] = useState(
    initialDate || new Date(new Date().getFullYear(), 0, 1)
  );
  const [lastDate, setLastDate] = useState(
    finalDate || new Date(new Date().getFullYear(), 11, 31)
  );
  const [totalData, setTotalData] = useState(0);
  const [partialData, setPartialData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [firstButton, setFirstButton] = useState(2);
  const [centerButton, setCenterButton] = useState(3);
  const [lastButton, setLastButton] = useState(4);

  const customStyles: IDataTableStyles = useMemo(() => {
    return {
      noData: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
          fontWeigth: 'bold',
          fontSize: '2rem',
        },
      },
      header: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
      },
      headRow: {
        style: {
          border: 'none',
        },
      },
      rows: {
        style: {
          backgroundColor: '#202020',
          '&:not(:last-of-type)': {
            border: 'none',
          },
        },
      },
      headCells: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
        activeSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
          },
          '&:hover:not(:focus)': {
            color: '#dadada',
          },
        },
        inactiveSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
            color: '#dadada',
          },
          '&:hover': {
            color: '#dadada',
          },
        },
      },
      cells: {
        style: {
          color: '#8c8c8c',
          backgroundColor: '#202020',
        },
      },
      contextMenu: {
        style: {
          color: '#dadada',
        },
      },
      action: {
        button: '#8c8c8c',
        hover: '#8c8c8c',
        disabled: '#8c8c8c',
      },
    };
  }, []);

  const tableData = useMemo(() => {
    setTotalData(data.length);
    return data;
  }, [data]);

  const tableDataShow = useMemo(() => {
    const newDataList = tableData.filter(
      (_, index) =>
        index >= (selectedPage - 1) * 10 && index < selectedPage * 10
    );
    if (partialData === 0) {
      setPartialData(newDataList.length);
    }
    return newDataList;
  }, [partialData, selectedPage, tableData]);

  const dataToExport = useMemo(() => {
    return tableDataShow;
  }, [tableDataShow]);

  const totalPages = useMemo(() => {
    const pages = Math.ceil(tableData.length / 10);
    return pages;
  }, [tableData]);

  const onChangePage = useCallback(
    (page) => {
      setSelectedPage(page);
      if (page >= 3) {
        if (page <= totalPages - 2) {
          setFirstButton(page - 1);
        } else if (page === totalPages) {
          if (totalPages === 3) {
            setFirstButton(page - 1);
          } else if (totalPages === 4) {
            setFirstButton(page - 2);
          } else {
            setFirstButton(page - 3);
          }
        } else if (totalPages === 4) {
          setFirstButton(page - 1);
        } else {
          setFirstButton(page - 2);
        }
      } else if (page === 1) {
        setFirstButton(page + 1);
      } else {
        setFirstButton(page);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setCenterButton(page);
        } else if (page === totalPages) {
          if (totalPages === 4) {
            setCenterButton(page - 1);
          } else {
            setCenterButton(page - 2);
          }
        } else if (totalPages === 4) {
          setCenterButton(page);
        } else {
          setCenterButton(page - 1);
        }
      } else if (page === 1) {
        setCenterButton(page + 2);
      } else {
        setCenterButton(page + 1);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setLastButton(page + 1);
        } else if (page === totalPages) {
          setLastButton(page - 1);
        } else {
          setLastButton(page);
        }
      } else if (page === 1) {
        setLastButton(page + 3);
      } else {
        setLastButton(page + 2);
      }

      setPartialData(10 * page);
    },
    [totalPages]
  );

  const handleChange = useCallback(
    (e) => {
      if (onSearch) {
        setSelectedPage(1);
        onSearch(e.target.value);
      }
    },
    [onSearch]
  );

  const handleChangeStartDate = useCallback(
    (e) => {
      setStartDate(e);
      if (onChangeStartDate) {
        setSelectedPage(1);
        onChangeStartDate(e);
      }
    },
    [onChangeStartDate]
  );

  const handleChangeLastDate = useCallback(
    (e) => {
      setLastDate(e);
      if (onChangeLastDate) {
        setSelectedPage(1);
        onChangeLastDate(e);
      }
    },
    [onChangeLastDate]
  );

  return (
    <TableContent
      className={`p-relative overflow-auto px-3 px-sm-4 px-lg-5 py-5 ${
        data.length > 0 ? 'mb-3' : ''
      }`}
      showHeader={!!(title || searchable || date || exportable)}
      rowIsClickable={!!onRowClicked}
    >
      {data.length > 0 ? (
        <>
          <div className="row w-100 justify-content-between p-absolute menu-table">
            {title && (
              <>
                <div className="col-lg-2">
                  <h2 className="h5">{title}</h2>
                </div>
                <div className="col-lg-1" />
              </>
            )}
            {searchable && (
              <div className="col-md-5 col-xl-4 pl-lg-5">
                <Search className="d-flex">
                  <input
                    className="w-100"
                    placeholder={language.component_table.placeholder}
                    onChange={handleChange}
                  />
                  <img
                    src={search}
                    alt={language.component_table.img_1}
                    className="mx-2"
                  />
                </Search>
              </div>
            )}
            {(date || exportable) && (
              <div className="col-md-6 col-xl-5 pr-lg-5 mt-3 mt-md-0">
                <div className="d-flex w-100">
                  {date && (
                    <>
                      <div className="mr-2 mr-sm-3">
                        <InputDate
                          onChange={handleChangeStartDate}
                          selected={startDate}
                          className="w-100 text-center inputDate"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="mr-2 mr-sm-3">
                        <InputDate
                          onChange={handleChangeLastDate}
                          selected={lastDate}
                          className="w-100 text-center inputDate"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                    </>
                  )}
                  {exportable && (
                    <CSVLink
                      data={dataToExport}
                      separator=";"
                      filename={`${
                        title ? title.replace(/\s/g, '-') : 'data-of-table'
                      }.csv`}
                      className="w-25 btn-export inputDate"
                      title={language.component_table.csv}
                    >
                      <img src={table} alt={language.component_table.img_2} />
                    </CSVLink>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="tableData my-5 crowdfunding-table">
            <DataTable
              data={tableDataShow}
              columns={columns}
              customStyles={style || customStyles}
              onRowClicked={onRowClicked}
              noDataComponent={<p className=""> </p>}
            />
          </div>
          {pagination && (
            <Pagination className="row pb-1 pt-3 w-100 mx-auto align-items-center justify-content-center menu-table">
              <div className="col-lg-6 text-center text-lg-left px-lg-4">
                <p className="mb-lg-0">
                  {language.component_table.p_1_1}{' '}
                  {partialData < 10 ? 1 : partialData - 9}-
                  {partialData > totalData ? totalData : partialData}{' '}
                  {language.component_table.p_1_2} {totalData}
                </p>
              </div>
              <div className="col-lg-6">
                <div className="d-flex w-100 justify-content-center justify-content-lg-end">
                  <button
                    type="button"
                    className="border-0 bg-transparent mx-1 px-1"
                    onClick={() => onChangePage(1)}
                  >
                    {language.component_table.button_1}
                  </button>
                  <button
                    type="button"
                    className={`border-0 bg-transparent mx-1 px-1 ${
                      selectedPage === 1 ? 'active' : ''
                    }`}
                    onClick={() => onChangePage(1)}
                  >
                    01
                  </button>
                  {totalPages > 1 && (
                    <>
                      {totalPages > 5 && (
                        <>
                          {selectedPage > 3 && (
                            <span className="border-0 bg-transparent mx-1 px-1">
                              ...
                            </span>
                          )}
                        </>
                      )}
                      {totalPages > 2 && (
                        <button
                          type="button"
                          className={`border-0 bg-transparent mx-1 px-1 ${
                            selectedPage === firstButton ? 'active' : ''
                          }`}
                          onClick={() => onChangePage(firstButton)}
                        >
                          {`0${firstButton}`.slice(-2)}
                        </button>
                      )}
                      {totalPages > 3 && (
                        <button
                          type="button"
                          className={`border-0 bg-transparent mx-1 px-1 ${
                            selectedPage === centerButton ? 'active' : ''
                          }`}
                          onClick={() => onChangePage(centerButton)}
                        >
                          {`0${centerButton}`.slice(-2)}
                        </button>
                      )}
                      {totalPages > 4 && (
                        <button
                          type="button"
                          className={`border-0 bg-transparent mx-1 px-1 ${
                            selectedPage === lastButton ? 'active' : ''
                          }`}
                          onClick={() => onChangePage(lastButton)}
                        >
                          {`0${lastButton}`.slice(-2)}
                        </button>
                      )}
                      {totalPages > 5 && (
                        <>
                          {selectedPage < totalPages - 2 && (
                            <span className="border-0 bg-transparent mx-1 px-1">
                              ...
                            </span>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {totalPages > 1 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 ${
                        selectedPage === totalPages ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(totalPages)}
                    >
                      {`0${totalPages}`.slice(-2)}
                    </button>
                  )}
                  <button
                    type="button"
                    className="border-0 bg-transparent mx-1 px-1"
                    onClick={() => onChangePage(totalPages)}
                  >
                    {language.component_table.button_2}
                  </button>
                </div>
              </div>
            </Pagination>
          )}
        </>
      ) : (
        <div className="d-flex text-center justify-content-center align-items-center">
          <p className="h5 mb-0">{language.component_table.p_2}</p>
        </div>
      )}
    </TableContent>
  );
};

export default Table;
