import styled from 'styled-components';

export const Container = styled.div`
  .categories {
    height: 300px;
    overflow: auto;
    button {
      background-color: transparent;
      border: none;
      text-align: start;
      color: rgba(96, 96, 96, 1) !important;
      font-weight: 400;
      :hover {
        background-color: rgba(45, 45, 45, 1);
        border-radius: 15px;
        color: rgba(203, 204, 206, 1) !important;
      }
    }
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(96, 96, 96, 1);
    }
    button.selected {
      background-color: rgba(45, 45, 45, 1);
      border-radius: 15px;
      color: #8c8c8c !important;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 767px) {
    .categories {
      height: 130px;
    }
  }
`;
