import { format } from 'date-fns';
import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';

import { Container, Content, Welcome } from './styles';
import Graph from '~/components/Graph';
import Tasks from '~/components/Tasks';
import Notifications from '~/components/Notifications';

import members from '~/assets/icons/members-icon.svg';
import reveues from '~/assets/icons/reveues-icon.svg';
import search from '~/assets/icons/search-icon.svg';
import totalReveues from '~/assets/icons/total-reveues-icon.svg';
import notificationIcon from '~/assets/icons/notification.svg';

import { useLanguage } from '~/hooks/Language';

interface IStudent {
  id: string;
  name: string;
  avatar: {
    avatar_url: string;
  };
  created_at: string;
}

interface IOrders {
  amount_paid: string;
  created_at: string;
  deleted_at: string;
}

interface IReferrer {
  id: string;
}
interface IAffiliateResponse {
  name: string;
  avatar: {
    avatar_url: string;
  };
  affiliate: {
    referrers: IReferrer[];
  };
}
interface IAffiliate {
  name: string;
  avatar: string;
  referred_members: number;
}

const Home: React.FC = () => {
  const { user } = useAuth();
  const userName = user.name.split(' ');
  const { language } = useLanguage();
  const [newRevenuePercent, setNewRevenuePercent] = useState(0);
  const [newRevenue, setNewRevenue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRevenueDiff, setTotalRevenueDiff] = useState(0);
  const [totalRevenuePrice, setTotalRevenuePrice] = useState('');
  const [newMembersPercent, setNewMembersPercent] = useState(0);
  const [newMembers, setNewMembers] = useState<IStudent[]>([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [memberRefund, setMemberRefund] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showMembers, setShowMembers] = useState(5);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api
      .get('students/coaches')
      .then((response) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const data = response.data.filter((student: IStudent) => {
          const studentDate = new Date(student.created_at);
          const studentMonth = studentDate.getMonth();

          return currentMonth === studentMonth;
        });

        let percent = 0;
        if (data.length === 0) {
          percent = 0;
        } else if (response.data.length - data.length === 0) {
          percent = 100;
        } else {
          percent = (data.length * 100) / (response.data.length - data.length);
        }

        const monthsNames: string[] = [];
        const membersArr: number[] = [];
        for (let i = 5; i >= 0; i -= 1) {
          const monthCalc = currentMonth - i;
          let year = currentYear;
          if (monthCalc < 0) {
            year -= 1;
          }
          const date = new Date(year, monthCalc);
          monthsNames.push(format(date, 'MMMM'));
          const month = date.getMonth();

          const dataByMonth = response.data.filter((student: any) => {
            const studentDate = new Date(student.created_at);
            const studentMonth = studentDate.getMonth();
            return month === studentMonth;
          });
          membersArr.push(dataByMonth.length);
        }

        setNewMembersPercent(percent);
        setNewMembers(data);
        setTotalMembers(response.data.length);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const total = totalRevenue - memberRefund;
    setTotalRevenueDiff(total);
    setTotalRevenuePrice(formatPrice(total));
  }, [memberRefund, totalRevenue]);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 1400) {
      setShowMembers(5);
    } else if (width >= 1300) {
      setShowMembers(7);
    } else if (width >= 1200) {
      setShowMembers(6);
    } else if (width >= 992) {
      setShowMembers(5);
    } else if (width >= 768) {
      setShowMembers(8);
    } else {
      setShowMembers(5);
    }
  }, [width]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row mt-3 mt-xl-4">
          <div className="col-12 mb-2 px-0 ">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-12 col-xxl-9 px-0">
                    <h1 className="h2">
                      {language.home.h1} {userName[0].trim()}!
                    </h1>
                  </div>
                  <div className="col-12 col-xxl-3 d-flex align-items-center px-0 my-4 my-xxl-0 justify-content-xxl-center">
                    <h2 className="h6 h5-xxl mb-0 light-grey-text">
                      {language.home.h2_1}
                    </h2>
                    <img
                      src={notificationIcon}
                      className="ml-4"
                      alt={language.home.img_1}
                    />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>

          <div className="col p-0 order-1 order-xxl-0">
            <Content className="h-xxl-100">
              <div className="container-fluid">
                <div className="row mb-lg-3">
                  <div className="col-12 px-0 px-sm-1 d-lg-none mb-4">
                    <Graph />
                  </div>
                  <div className="col-sm-6 col-lg-3 pl-sm-1 px-md-1 px-0">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        {language.home.p_1}
                      </p>
                      <div className="d-flex justify-content-between align-items-end">
                        <img
                          src={members}
                          alt={language.home.img_2}
                          className="icons pb-2"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0">
                            <small
                              className={`${loading && 'skeleton mb-2 px-2'}`}
                            >
                              +{newMembersPercent.toFixed(2)}%
                            </small>
                          </p>
                          <p
                            className={`${
                              loading && 'skeleton px-2'
                            } mb-0 h3 h4-lg h3-xl font-weight-400 text-green`}
                          >
                            +{newMembers.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 pt-3 pt-sm-0 pl-sm-3 pr-md-1 px-0">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        {language.home.p_2}
                      </p>
                      <div className="d-flex justify-content-between align-items-end">
                        <img
                          src={members}
                          alt={language.home.img_3}
                          className="icons pb-2"
                        />
                        <div>
                          <p className="small text-green mb-0">
                            <small
                              className={`${loading && 'skeleton mb-2 px-2'}`}
                            >
                              +{newMembersPercent.toFixed(2)}%
                            </small>
                          </p>
                          <div className="text-right">
                            <p
                              className={`${
                                loading && 'skeleton'
                              } mb-0 h3 h4-lg h3-xl font-weight-400`}
                            >
                              {totalMembers}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 pl-sm-1 pl-lg-3 pt-3 pt-lg-0 px-md-1 px-0">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        {language.home.p_3}
                      </p>
                      <div className="d-flex justify-content-between align-items-end">
                        <img
                          src={reveues}
                          alt={language.home.img_4}
                          className="icons pb-2"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0">
                            <small className={`${loading && 'skeleton mb-2'}`}>
                              +{newRevenuePercent.toFixed(2)}%
                            </small>
                          </p>
                          <p
                            className={`${
                              loading && 'skeleton px-2'
                            } mb-0 h3 h4-lg h3-xl font-weight-400 text-green`}
                          >
                            +{newRevenue}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 pl-sm-3 pt-3 pt-lg-0 pr-md-1 px-0">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        {language.home.p_4}
                      </p>
                      <div className="d-flex justify-content-between align-items-end">
                        <img
                          src={totalReveues}
                          alt={language.home.img_5}
                          className="icons pb-2"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0">
                            <small className={`${loading && 'skeleton mb-2'}`}>
                              +{newMembersPercent.toFixed(2)}%
                            </small>
                          </p>
                          <p
                            className={`${
                              loading && 'skeleton'
                            } mb-0 font-weight-400 h3 h4-lg h3-xl ${
                              totalRevenueDiff >= 0 ? 'text-green' : 'text-red'
                            }`}
                          >
                            {totalRevenuePrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 px-0 px-sm-1 d-flex flex-column justify-content-between">
                    <div className="d-none d-lg-block">
                      <Graph />
                    </div>
                  </div>
                  <div className="col-lg-4 pl-lg-3 px-0 px-sm-1 order-2 order-lg-1 mt-4 mt-lg-0">
                    <div className="h-100 bg-gray">
                      <div className="d-sm-flex d-lg-block align-items-center">
                        <h3 className="h5 mt-2 mb-3 my-md-0 mt-lg-2 mb-lg-3 w-sm-50 w-lg-100">
                          {language.home.h3}
                        </h3>
                        <div className="bg-light-grey rounded-pill input-members my-4 w-sm-50 w-lg-100">
                          <div className="d-flex py-1 px-3">
                            <input
                              type="text"
                              className="border-0 bg-transparent w-100 py-1"
                              placeholder={language.home.placeholder}
                            />
                            <button
                              type="submit"
                              className="border-0 bg-transparent"
                            >
                              <img src={search} alt={language.home.img_6} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <ol>
                        {/* skeleton */}
                        <li
                          className={`${
                            loading && 'd-flex'
                          } d-none align-items-center p-2 border-bottom`}
                        >
                          <p className={`${loading && 'skeleton'} mb-0`}>00.</p>
                          <div
                            className={`${
                              loading && 'skeleton'
                            } avatar-coach mx-3`}
                          />
                          <p className={`${loading && 'skeleton'} mb-0`}>
                            ##### #####
                          </p>
                        </li>
                        <li
                          className={`${
                            loading && 'd-flex'
                          } d-none align-items-center p-2 border-bottom`}
                        >
                          <p className={`${loading && 'skeleton'} mb-0`}>00.</p>
                          <div
                            className={`${
                              loading && 'skeleton'
                            } avatar-coach mx-3`}
                          />
                          <p className={`${loading && 'skeleton'} mb-0`}>
                            ##### #####
                          </p>
                        </li>
                        <li
                          className={`${
                            loading && 'd-flex'
                          } d-none align-items-center p-2 border-bottom`}
                        >
                          <p className={`${loading && 'skeleton'} mb-0`}>00.</p>
                          <div
                            className={`${
                              loading && 'skeleton'
                            } avatar-coach mx-3`}
                          />
                          <p className={`${loading && 'skeleton'} mb-0`}>
                            ##### #####
                          </p>
                        </li>
                        {/* fim */}
                        {newMembers.map((newMember, index) => (
                          <>
                            {index < showMembers && (
                              <li
                                key={newMember.id}
                                className="d-flex w-md-50 w-lg-100 align-items-center my-2 pb-2 border-bottom px-sm-3"
                              >
                                <p className="mb-0 font-weight-400">
                                  {index + 1}.
                                </p>
                                <img
                                  src={newMember.avatar.avatar_url}
                                  alt={newMember.name}
                                  className="avatar-coach mx-3"
                                />
                                <p className="mb-0 font-weight-300">
                                  {newMember.name}
                                </p>
                              </li>
                            )}
                          </>
                        ))}
                      </ol>
                    </div>
                  </div>
                  <div className="col-12 order-1 order-lg-2">
                    <div className="row mt-3">
                      <div className="col-sm-6 col-lg-3 pl-sm-1 px-md-1 px-0">
                        <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                          <p className="small font-weight-300">
                            {language.home.p_6}
                          </p>
                          <div className="d-flex justify-content-between align-items-end">
                            <img
                              src={members}
                              alt={language.home.img_7}
                              className="icons pb-2"
                            />
                            <div className="text-right">
                              <p className="small text-green mb-0">
                                <small
                                  className={`${loading && 'skeleton mb-2'}`}
                                >
                                  +{newMembersPercent.toFixed(2)}%
                                </small>
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton px-2'
                                } mb-0 h3 h4-lg h3-xl font-weight-400 text-green`}
                              >
                                +{newMembers.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3 pt-3 pt-sm-0 pl-sm-3 pr-md-1 px-0">
                        <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                          <p className="small font-weight-300">
                            {language.home.p_7}
                          </p>
                          <div className="d-flex justify-content-between align-items-end">
                            <img
                              src={members}
                              alt={language.home.img_8}
                              className="icons pb-2"
                            />
                            <div className="text-right">
                              <p className="small text-green mb-0">
                                <small
                                  className={`${loading && 'skeleton mb-2'}`}
                                >
                                  +{newMembersPercent.toFixed(2)}%
                                </small>
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton px-2'
                                } mb-0 h3 h4-lg h3-xl font-weight-400 text-green`}
                              >
                                +{newMembers.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3 pl-sm-1 pl-lg-3 pt-3 pt-lg-0 px-md-1 px-0">
                        <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                          <p className="small font-weight-300">
                            {language.home.p_8}
                          </p>
                          <div className="d-flex justify-content-between align-items-end">
                            <img
                              src={members}
                              alt={language.home.img_9}
                              className="icons pb-2"
                            />
                            <div className="text-right">
                              <p className="small text-danger mb-0">
                                <small
                                  className={`${loading && 'skeleton mb-2'}`}
                                >
                                  +{newMembersPercent.toFixed(2)}%
                                </small>
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton'
                                } mb-0 h3 h4-lg h3-xl font-weight-400 text-danger`}
                              >
                                +{newMembers.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3 pl-sm-3 pt-3 pt-lg-0 pr-md-1 px-0">
                        <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                          <p className="small font-weight-300">
                            {language.home.p_9}
                          </p>
                          <div className="d-flex justify-content-between align-items-end">
                            <img
                              src={members}
                              alt={language.home.img_10}
                              className="icons pb-2"
                            />
                            <div className="text-right">
                              <p className="small text-danger mb-0">
                                <small
                                  className={`${loading && 'skeleton mb-2'}`}
                                >
                                  +{newMembersPercent.toFixed(2)}%
                                </small>
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton'
                                } mb-0 h3 h4-lg h3-xl font-weight-400 text-danger`}
                              >
                                +{newMembers.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </div>
          <div className=" col-md-12 order-0 order-xxl-1 col-xxl-3 px-0 pl-xxl-4 pr-xxl-0 mb-4 mb-xxl-0">
            <div className="row mx-0 w-100 mh-100 justify-content-between">
              <div className="col-lg-6 col-xl-7 col-xxl-12 pb-xxl-3 px-0 pr-lg-3 px-xxl-0">
                <Notifications />
              </div>

              <div className="d-none h-xxl-300 d-lg-block tasks p-4 col-lg-6 col-xl-5 col-xxl-12">
                <Tasks />
              </div>
            </div>
          </div>
          <div className="d-lg-none tasks p-4 min-height col-12 order-2 px-0 my-4">
            <Tasks />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
