import styled from 'styled-components';

export const Container = styled.div`
  background: #242526;
  border-radius: 20px;
  padding: 20px;

  h3 {
    color: #cbccce;
    font-size: 18px !important;
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: block;
    box-shadow: none;

    .apexcharts-xaxis {
      display: none !important;
    }

    .apexcharts-text {
      display: none !important;
    }

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
