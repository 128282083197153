/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface ILanguage {
  sign_in: {
    h1: string;
    h2: string;
    p_1: string;
    label_1: string;
    label_2: string;
    a: string;
    button: string;
  };

  yup: {
    email: string;
    required: string;
    password: string;
    error: string;
  };

  yup_profile: {
    name: string;
    email_valid: string;
    email_required: string;
    phone: string;
    bio: string;
    quote: string;
    display_name: string;
    display_phone: string;
    display_email_valid: string;
    display_email_required: string;
    zipCode: string;
    country: string;
    street: string;
    number: string;
    neighborhood: string;
    state: string;
    city: string;
    complement: string;
    skype: string;
    languages: string;
    success_1: string;
    success_2: string;
    error: string;
  };

  home: {
    h1: string;
    h2_1: string;
    img_1: string;
    p_1: string;
    img_2: string;
    p_2: string;
    img_3: string;
    p_3: string;
    img_4: string;
    p_4: string;
    img_5: string;
    h3: string;
    placeholder: string;
    img_6: string;
    p_6: string;
    img_7: string;
    p_7: string;
    img_8: string;
    p_8: string;
    img_9: string;
    p_9: string;
    img_10: string;
  };

  header: {
    a_1: string;
    a_2: string;
    a_3: string;
    a_4: string;
    a_5: string;
    button_1: string;
    button_2: string;
    button_3: string;
  };

  members_list: {
    student: string;
    visitor: string;
    without_course: string;
    joined: string;
    user: string;
    name: string;
    type: string;
    referrer: string;
    product: string;
    email: string;
    h1: string;
  };

  members_update: {
    student: string;
    franchise_owner: string;
    affiliate: string;
    h1: string;
    h2_1: string;
    label_1: string;
    label_2: string;
    label_3: string;
    h2_2: string;
    label_4: string;
    label_5: string;
    label_6: string;
    label_7: string;
    p_videos: string;
    p_complete: string;
    button_1: string;
    button_2: string;
    modal_h4: string;
    modal_p_1_1: string;
    modal_p_1_2: string;
    modal_img_1: string;
    modal_p_2: string;
    modal_img_2: string;
    modal_img_3: string;
    modal_p_3: string;
    modal_button: string;
  };

  calendar: {
    month_1: string;
    month_2: string;
    month_3: string;
    month_4: string;
    month_5: string;
    month_6: string;
    month_7: string;
    month_8: string;
    month_9: string;
    month_10: string;
    month_11: string;
    month_12: string;
    h1: string;
    button_1: string;
    button_2: string;
    p_1: string;
    span_1: string;
    p_2: string;
    span_2: string;
    p_3: string;
    span_3: string;
    p_4: string;
    span_4: string;
    p_5: string;
    span_5: string;
    p_6: string;
    span_6: string;
    p_7: string;
    span_7: string;
    h3: string;
    h5: string;
  };

  profile: {
    h1: string;
    h3_1: string;
    label_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
    h3_2: string;
    label_5: string;
    label_6: string;
    label_7: string;
    label_8: string;
    label_9: string;
    label_10: string;
    label_11: string;
    label_12: string;
    label_13: string;
    label_14: string;
    label_15: string;
    label_16: string;
    label_17: string;
    bio_photo: string;
    p_1: string;
    p_2: string;
    label_18: string;
    a: string;
    button: string;
  };

  profile_bio: {
    h2: string;
    li: string;
    button: string;
  };

  faq: {
    h1: string;
    placeholder: string;
    img_1: string;
    p: string;
  };

  component_graph: {
    members: string;
    sales: string;
    h3: string;
    button: string;
    img: string;
  };

  component_tasks: {
    h3_1: string;
    h3_2: string;
    img: string;
    button: string;
    modal_img: string;
    modal_h4: string;
    modal_p: string;
    modal_placeholder_1: string;
    modal_placeholder_2: string;
    modal_button: string;
  };

  component_table: {
    placeholder: string;
    img_1: string;
    csv: string;
    img_2: string;
    p_1_1: string;
    p_1_2: string;
    button_1: string;
    button_2: string;
    p_2: string;
  };

  component_courses: {
    h2: string;
  };

  component_notifications: {
    img_1: string;
    p: string;
  };

  forgot_password: {
    arrow: string;
    h1: string;
    h2: string;
    label_1: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    success: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
}

interface LanguageContextData {
  language: ILanguage;
  handleSelectIdiom(idiom: string): void;
  idiom: string;
}

export const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData
);

export const LanguageProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [idiom, setIdiom] = useState(() => {
    let lang = location.pathname.substr(1, 2);
    if (lang !== 'en' && lang !== 'es') {
      lang = localStorage.getItem('@Membership:language') || 'es';
    }
    const element = document.getElementsByTagName('html');
    element[0].lang = lang;
    return lang;
  });

  const handleSelectIdiom = useCallback((idiomSelected) => {
    setIdiom(idiomSelected);
    const element = document.getElementsByTagName('html');
    element[0].lang = idiomSelected;
  }, []);

  const language = useMemo(() => {
    const checkIdiom = localStorage.getItem('@Membership:language');
    if (checkIdiom !== idiom) {
      const lang = location.pathname.substr(1, 2);
      if (lang !== 'en' && lang !== 'es') {
        history.push(`${process.env.PUBLIC_URL}/${idiom}${location.pathname}`);
      } else {
        history.push(
          `${process.env.PUBLIC_URL}/${idiom}${location.pathname.substr(3)}`
        );
      }
    }

    localStorage.setItem('@Membership:language', idiom);

    return require(`./languages/${idiom}`);
  }, [history, idiom, location.pathname]);

  return (
    <LanguageContext.Provider value={{ language, handleSelectIdiom, idiom }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
