import styled from 'styled-components';
import banner from '~/assets/banners/banner-faq.jpg';

export const Container = styled.div`
  min-height: calc(100vh - 60px);

  .h-40vh {
    height: 40vh;
  }

  .text-no-courses {
    color: #606060;
    font-weight: 600;
  }
`;

export const Welcome = styled.div`
  background-image: url(${banner});
  background-position: center;
  background-size: cover;
  box-shadow: 13px 5px 15px rgb(0 0 0 / 10%);
  p {
    color: #969696;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: none;
    background-color: rgba(73, 73, 73, 1);

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      color: rgba(203, 204, 206, 1);
      background-color: rgba(73, 73, 73, 1);
    }
  }
`;

export const Question = styled.div`
  .question {
    transition-duration: 0.3s;
    background: #292929;
    color: #cbccce;
    border-radius: 20px;
    svg {
      min-width: 25px;
      min-height: 25px;
    }
  }

  .answer {
    transition-duration: 0.3s;
    background: #202020;
    color: #8c8c8c;
    border-radius: 0 0 15px 15px;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }

  .question-box.active {
    .question {
      border-radius: 15px 15px 0px 0px;

      svg {
        transform: rotate(180deg);
        transition-duration: 0.5s;
        min-width: 25px;
        min-height: 25px;
      }
    }

    .answer {
      height: 250px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      p {
        white-space: pre-line;
        overflow: auto;
        height: 203px;

        ::-webkit-scrollbar {
          background-color: transparent !important;
          width: 7px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: #292929;
        }
      }
    }
  }
`;

export const ChatButton = styled.a`
  width: 103px;
  height: 103px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  cursor: pointer;
  border: none;
  padding: 2px;
  background-color: rgba(96, 96, 96, 1);
  border-radius: 10px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #fff;

  @media screen and (max-width: 575px) {
    width: 80px;
    height: 80px;

    img {
      height: 38px;
    }
  }
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
