import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import api from '~/services/api';

import {
  Container,
  Welcome,
  NameStudentTable,
  OptionStudent,
  Skeleton,
} from './styles';
import Table from '~/components/Table';
import options from '~/assets/icons/options.svg';
import { useLanguage } from '~/hooks/Language';

interface ITableData {
  dataJoined: string;
  userID: string;
  avatar: string;
  name: string;
  member_type: string;
  referrer: string;
  produtc_owned: string;
  email: string;
  phone: string;
}

interface ICourseStudent {
  course: {
    title: string;
  };
}

interface IStudent {
  created_at: string;
  referral_code: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  courseStudent: ICourseStudent[];
  email: string;
  phone: string;
}

const Students: React.FC = () => {
  const { language } = useLanguage();
  const hisotry = useHistory();
  const [students, setStudents] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get('students/coaches').then((response) => {
      const data = response.data.map((student: IStudent) => ({
        dataJoined: format(parseISO(student.created_at), 'MMM-dd-yyyy'),
        userID: student.referral_code,
        avatar: student.avatar.avatar_url,
        name: student.name,
        member_type:
          student.courseStudent.length > 0
            ? language.members_list.student
            : language.members_list.visitor,
        referrer: student.referral_code,
        produtc_owned:
          student.courseStudent.length > 0
            ? student.courseStudent[0].course.title
            : language.members_list.without_course,
        email: student.email,
      }));
      setStudents(data);
      setLoading(false);
    });
  }, [
    language.members_list.student,
    language.members_list.visitor,
    language.members_list.without_course,
  ]);

  const handleSelectedStudent = useCallback(
    (e) => {
      hisotry.push(`members/${e.userID}`);
    },
    [hisotry]
  );

  const columns = useMemo(
    () => [
      {
        name: language.members_list.joined,
        selector: 'dataJoined',
        sortable: true,
      },
      {
        name: language.members_list.user,
        selector: 'userID',
        sortable: true,
      },
      {
        name: language.members_list.name,
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameStudentTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameStudentTable>
        ),
      },
      {
        name: language.members_list.type,
        selector: 'member_type',
        sortable: true,
      },
      {
        name: language.members_list.referrer,
        selector: 'referrer',
        sortable: true,
      },
      {
        name: language.members_list.product,
        selector: 'produtc_owned',
        sortable: true,
      },
      {
        name: language.members_list.email,
        selector: 'email',
        sortable: true,
      },
      {
        name: '',
        selector: 'Options',
        sortable: true,
        cell: (row: ITableData) => (
          <OptionStudent
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
            onClick={() => handleSelectedStudent(row)}
          >
            <img src={options} alt="options" className="mr-2" />
          </OptionStudent>
        ),
      },
    ],
    [
      handleSelectedStudent,
      language.members_list.email,
      language.members_list.joined,
      language.members_list.name,
      language.members_list.product,
      language.members_list.referrer,
      language.members_list.type,
      language.members_list.user,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm my-4">
                      {language.members_list.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            {loading && (
              <Skeleton className="col-12 mt-4 mt-lg-0 px-lg-4 py-5 d-flex flex-column">
                <div className="row px-lg-4 justify-content-between">
                  <div className="col-md-5 col-lg-4 my-2">
                    <div className="skeleton h-36 w-100" />
                  </div>
                  <div className="col-md-6 col-lg-7 col-xxl-5 my-2 d-flex justify-content-between justify-content-lg-end">
                    <button
                      type="button"
                      className="w-40 h-36 skeleton px-5 py-1 mr-lg-2"
                    >
                      00/00/0000
                    </button>
                    <button
                      type="button"
                      className="w-40 h-36 skeleton px-5 py-1 mr-lg-2"
                    >
                      00/00/0000
                    </button>
                    <button
                      type="button"
                      className="w-15 h-36 skeleton px-4 py-1 mr-lg-2"
                    >
                      00/00
                    </button>
                  </div>
                </div>

                <div className="row mt-5 px-4 align-items-center">
                  <div className="col my-3 small">
                    <p className="skeleton mb-0 px-2">Fecha de la</p>
                  </div>
                  <div className="col my-3 small">
                    <p className="skeleton mb-0 px-2">Id del pedido</p>
                  </div>
                  <div className="col d-none d-sm-block my-3 small">
                    <p className="skeleton mb-0 px-2">Usuario</p>
                  </div>
                  <div className="col d-none d-sm-block my-3 small">
                    <p className="skeleton mb-0 px-2">Producto</p>
                  </div>
                  <div className="col d-none d-md-block my-3 small">
                    <p className="skeleton mb-0 px-2">Cantidad pa</p>
                  </div>
                  <div className="col-lg d-none d-lg-block my-3 small">
                    <p className="skeleton mb-0 px-2">Comisión</p>
                  </div>
                  <div className="col-lg d-none d-xl-block my-3 small">
                    <p className="skeleton mb-0 px-2">Comisión</p>
                  </div>
                  <div className="col-lg d-none d-xl-block my-3 small" />
                </div>
                <div className="row mb-5 px-4 align-items-center">
                  <div className="col mt-2 small">
                    <p className="skeleton mb-0 px-2">00-00-0000</p>
                  </div>
                  <div className="col mt-2 small">
                    <p className="skeleton mb-0 px-2">#######-##</p>
                  </div>
                  <div className="col d-none d-sm-flex justify-content-center small">
                    <div className="skeleton img-height rounded-circle" />
                    <p className="skeleton ml-2 mb-0 px-2">########</p>
                  </div>
                  <div className="col d-none d-sm-block mt-2 small">
                    <p className="skeleton mb-0 px-2">#########</p>
                  </div>
                  <div className="col d-none d-md-block mt-2 small">
                    <p className="skeleton mb-0 px-2">######</p>
                  </div>
                  <div className="col-lg d-none d-lg-block mt-2 small">
                    <p className="skeleton mb-0 px-2">######</p>
                  </div>
                  <div className="col-lg d-none d-xl-block mt-2 small">
                    <p className="skeleton mb-0 px-2">######</p>
                  </div>
                  <div className="col-lg d-none d-xl-block mt-2 small">
                    <p className="skeleton mb-0 px-2">#</p>
                  </div>
                </div>

                <div className="row pb-1 pt-3 mt-auto w-100 align-items-center justify-content-center menu-table mx-0 sc-jqCOkK hpTZXp">
                  <div className="col-lg-6 px-lg-4 text-center text-lg-left">
                    <p className="skeleton mb-lg-0">Demostración 1-1 de 1</p>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex w-100 justify-content-center justify-content-lg-end">
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-1 px-1"
                      >
                        Primero
                      </button>
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-0 mx-sm-0 px-1"
                      >
                        01
                      </button>
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-1 px-1"
                      >
                        Último
                      </button>
                    </div>
                  </div>
                </div>
              </Skeleton>
            )}
            {!loading && (
              <Table
                data={students}
                columns={columns}
                pagination
                date
                searchable
                exportable
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Students;
