import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  max-height: 483px;
  overflow: auto;

  .skeleton p {
    color: transparent !important;
  }
  .notification {
    background: rgba(32, 32, 32, 1);
    border-radius: 25px;
  }

  .no-notifications {
    > p {
      color: rgba(96, 96, 96, 1);
    }
  }

  p {
    font-size: 18px;
  }

  h3 {
    border-left: 3px solid rgba(96, 96, 96, 1);
    color: rgba(96, 96, 96, 1);
  }

  .timer p {
    font-size: 12px !important;
    color: #cbccce;
  }

  @media screen and (max-width: 1399px) {
    .notification {
      min-width: 250px;
    }
  }

  @media screen and (min-width: 1400px) {
    .notification-group {
      + .notification-group {
        margin-top: 1.5rem !important;
        padding-top: 1.5rem !important;
        border-top: 1px solid rgba(51, 51, 51, 1);
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1399px) {
    .notification {
      height: 100%;
    }
  }
`;
